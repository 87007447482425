<template>
    <v-container>
        <v-row justify="space-between" class="mx-5 mb-5">
            <h5 class="text-h5 primary--text">Trips</h5>
            <v-btn elevation="0" class="px-10" link :to="{name: 'addAgencyTrip', params: {action: 'add'}}" rounded large color="primary white--text">ADD NEW TRIP</v-btn>
        </v-row>

        <v-tabs
        class="my-10"
        v-model="tab"
        background-color="transparent"
        color="primary"
        grow
        >
            <v-tab>
                Active Trips
            </v-tab>
            <v-tab>
                Inactive Trips
            </v-tab>
        </v-tabs>

        <div class="d-flex justify-center" v-if="loadingTrips">
            <v-progress-circular
            :size="150"
            color="primary"
            indeterminate
            class="my-10"
            ></v-progress-circular>
        </div>

        <v-row class="my-10" no-gutters v-else>
            <template v-if="trips.length">
                <v-col cols="12" sm="6" md="4" xl="3" v-for="item in trips" :key="item.id" class="pa-5">
                  <trip-card @swapStatus="updateStatus" :trip="item"></trip-card>
                </v-col>
            </template>
            <p class="mx-auto text-h5 my-10" v-else>No trips Yet!</p>
        </v-row>
    </v-container>
</template>

<script>
import vendors from '@/web_services/vendors'
import tripCard from '@/components/vendors/tripCard'

export default {
  components: {
    tripCard
  },
  data () {
    return {
      loadingTrips: true,
      tab: 0,
      trips: [],
      activeTrips: [],
      inactiveTrips: []
    }
  },
  watch: {
    tab (val) {
      if (!val) this.trips = this.activeTrips
      else this.trips = this.inactiveTrips
    }
  },
  methods: {
    updateStatus (trip) {
      // const formData = new FormData()
      // formData.append('_method', 'PUT')
      // formData.append('name', trip.name)
      // formData.append('date', trip.date)
      // formData.append('description', trip.description)
      // formData.append('itinerary', trip.itinerary)
      // formData.append('days', trip.days || 2)
      // formData.append('nights', trip.nights)
      // formData.append('occupancy', trip.occupancy)
      // formData.append('price_per_person', trip.price_per_person)
      // formData.append('solo_price', trip.solo_price || '')
      // formData.append('limo_price', trip.limo_price || '')
      // formData.append('city_id', trip.city.id)
      // formData.append('is_active', trip.is_active ? 1 : 0)
      // trip.payment_links.forEach((item, i) => {
      //   formData.append(`payment_links[${i}]`, item)
      // })
      // trip.tags.forEach((item, i) => {
      //   formData.append(`tags[${i}]`, item.id)
      // })
      // formData.append('image', this.image, this.image.name)
      // this.images.forEach((item, i) => {
      //   formData.append(`images[${i}]`, item, item.name)
      // })
      const body = {
        name: trip.name,
        description: trip.description,
        itinerary: trip.itinerary,
        date: trip.date,
        days: trip.days || 2,
        nights: trip.nights,
        occupancy: trip.occupancy,
        price_per_person: trip.price_per_person,
        solo_price: trip.solo_price || '',
        limo_price: trip.limo_price || '',
        city: trip.city,
        payment_links: trip.links,
        is_active: trip.is_active ? 1 : 0,
        tags: []
      }
      trip.tags.map(tag => body.tags.push(tag.id))
      vendors.updateTrip(trip.id, body).then(res => {
        if (trip.is_active) {
          this.inactiveTrips = this.inactiveTrips.filter(item => item.id !== trip.id)
          this.activeTrips.push(trip)
          this.trips = this.inactiveTrips
        } else {
          this.activeTrips = this.activeTrips.filter(item => item.id !== trip.id)
          this.inactiveTrips.push(trip)
          this.trips = this.activeTrips
        }
      }).catch(() => {})
    }
  },
  created () {
    vendors.getActiveTrips().then(res => {
      this.activeTrips = res.data.data
      this.trips = this.activeTrips
    }).catch(() => {
      this.activeTrips = []
    })
    vendors.getInactiveTrips().then(res => {
      this.inactiveTrips = res.data.data
    }).catch(() => {
      this.inactiveTrips = []
    }).finally(() => {
      this.loadingTrips = false
    })
  }
}
</script>
