<template>
    <v-container>
      <h5 class="text-h5 mt-0 mb-5 primary--text text-capitalize">{{$route.params.action}} Trip</h5>
      <v-expand-transition>
          <v-alert
          v-show="alert"
          border="left"
          text
          :type="alertType"
          >
            {{alertText}}
          </v-alert>
      </v-expand-transition>
      <v-card v-if="$route.params.action === 'add'" outlined class="rounded-xl pa-3">
          <v-card-title class="body-1 font-weight-bold">Upload Trip Photos</v-card-title>
          <v-card-text>
              <v-row no-gutters justify="space-between" align="center">
                  <v-col cols="12" md="9" class="d-flex flex-wrap">
                      <v-skeleton-loader
                      v-show="!previewImages.length"
                      v-for="n in 2"
                      :key="n"
                      type="image"
                      height="100"
                      width="200"
                      boilerplate
                      class="rounded-xl ma-3"
                      ></v-skeleton-loader>
                      <v-img v-for="(image, i) in previewImages" class="rounded-xl ma-3" max-width="150" :src="image" :key="image">
                          <v-btn @click="images.splice(i, 1)" icon color="white" large right absolute class="ma-1"><v-icon>mdi-close</v-icon></v-btn>
                      </v-img>
                  </v-col>
                  <v-col cols="12" md="2" @click="activateImgUpload">
                      <div class="d-flex mx-auto align-center py-7 justify-center rounded-xl upload-div">
                          <div class="text-center primary--text">
                              <v-icon color="primary" large>mdi-tray-arrow-up</v-icon>
                              <h4>Upload Images</h4>
                          </div>
                      </div>
                  </v-col>
              </v-row>
              <input
              ref="multipleUploader"
              class="d-none"
              type="file"
              accept="image/*"
              @change="onFileChanged"
              multiple
              >
          </v-card-text>
      </v-card>

      <v-card v-else outlined class="rounded-xl pa-3">
          <v-card-title class="body-1 font-weight-bold">Manage Trip Photos</v-card-title>
          <v-card-text>
              <v-row no-gutters justify="space-between" align="center">
                  <v-col cols="12" md="9" class="d-flex flex-wrap">
                      <v-skeleton-loader
                      v-show="!agencyTrip.images.length"
                      v-for="n in 2"
                      :key="n"
                      type="image"
                      height="100"
                      width="200"
                      boilerplate
                      class="rounded-xl ma-3"
                      ></v-skeleton-loader>
                      <v-img v-for="(image, i) in agencyTrip.images" class="rounded-xl ma-3" max-width="150" :src="image.image_name" :key="image.id">
                          <v-btn @click="agencyTrip.images.splice(i, 1); deleteTripImage(agencyTrip.id, image.id)" icon color="white" large right absolute class="ma-1"><v-icon>mdi-close</v-icon></v-btn>
                      </v-img>
                  </v-col>
                  <v-col cols="12" md="2" @click="singleImageUpload">
                      <div class="d-flex mx-auto align-center py-7 justify-center rounded-xl upload-div">
                          <div class="text-center primary--text">
                              <v-icon color="primary" large>mdi-tray-arrow-up</v-icon>
                              <h4>Upload Images</h4>
                          </div>
                      </div>
                  </v-col>
              </v-row>
              <input
              ref="singleUploader"
              class="d-none"
              type="file"
              accept="image/*"
              @change="singleImageHandler"
              >
          </v-card-text>
      </v-card>

      <v-form v-model="valid" ref="detailsForm">
        <v-row class="my-10">
            <v-col cols="12" md="5">
                <v-card class="rounded-xl pa-3">
                    <v-card-title class="body-1 font-weight-bold">Add Trip Location</v-card-title>
                    <GmapAutocomplete
                    class="google-ac round-edges"
                    @place_changed='setPlace'
                    />
                    <!-- <v-img max-height="200" src="../../assets/images/map-mini.png"></v-img> -->
                    <GmapMap
                    :center='center'
                    ref="gmap"
                    :zoom='8'
                    style='width:100%;  height: 200px;'
                    >
                      <GmapMarker
                        :key="index"
                        v-for="(m, index) in markers"
                        :position="m.position"
                      />
                    </GmapMap>
                </v-card>
            </v-col>
            <v-col cols="12" md="7">
                <v-card min-height="350" class="rounded-xl pa-3">
                    <v-card-title class="body-1 font-weight-bold">Add Trip Tags</v-card-title>
                    <v-card-text>
                      <v-chip large color="#F5F5F5" close @click:close="tags.splice(i, 1)" v-for="(item, i) in tags" :key="i" class="ma-1 px-5">
                        {{item.name}}
                        <v-rating
                        background-color="primary lighten-1"
                        color="primary"
                        empty-icon="mdi-star-outline"
                        full-icon="mdi-star"
                        length="5"
                        hover
                        v-model="item.rank"
                        size="15"
                        dense
                        :value="item.rank ? item.rank : 1"
                        ></v-rating>
                      </v-chip>
                    </v-card-text>
                    <v-card-actions>
                        <v-autocomplete
                        multiple
                        rounded
                        v-model="tags"
                        :items="allTags"
                        outlined
                        dense
                        placeholder="Add Trip Tag"
                        item-text="name"
                        item-value="id"
                        return-object
                        clearable
                        full-width
                        height="60"
                        class="py-1"
                        >
                          <!-- <template v-slot:selection="data">
                            <h4>{{data.item.name}}</h4>
                          </template>
                          <template v-slot:item="data">
                            <h4>{{data.item.name}}</h4>
                          </template> -->
                        </v-autocomplete>
                        <!-- <v-spacer></v-spacer> -->
                        <!-- <v-btn :disabled="!tag.length" @click="Array.prototype.push.apply(tags, tag)" text color="primary" class="mb-5"><v-icon left>mdi-plus-circle</v-icon>Add tags</v-btn> -->
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <v-row no-gutters class="text-capitalize">
            <v-col cols="12" class="px-2" md="6">
                <p class="body-1 font-weight-medium">trip name</p>
                <v-text-field :rules="requiredRule" v-model="name" placeholder="Trip name" rounded outlined></v-text-field>
            </v-col>
            <v-col cols="12" class="px-2" md="3">
              <p class="body-1 font-weight-medium">recommended date</p>
              <v-menu
              v-model="dateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                  rounded
                  :rules="requiredRule"
                  outlined
                  :value="new Date(date.replaceAll('-', '/')).toDateString()"
                  persistent-hint
                  v-bind="attrs"
                  v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                :nudge-bottom="50"
                :rules="requiredRule"
                v-model="date"
                @input="dateMenu = false"
                :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" class="px-2" md="3">
                <p class="body-1 font-weight-medium">trip image</p>
                <v-file-input
                :rules="$route.params.action === 'add' ? requiredRule : [true]"
                accept="image/*"
                placeholder="Pick an image"
                prepend-icon=""
                prepend-inner-icon="mdi-camera"
                rounded
                outlined
                v-model="image"
                ></v-file-input>
            </v-col>
            <v-col cols="12" class="px-2">
                <p class="body-1 font-weight-medium">description</p>
                <v-textarea
                :rules="requiredRule"
                v-model="description"
                outlined
                rounded
                placeholder="Description"
                ></v-textarea>
            </v-col>
            <v-col cols="12" class="px-2">
                <p class="body-1 font-weight-medium">trip itinerary</p>
                <v-textarea
                :rules="requiredRule"
                v-model="itinerary"
                outlined
                rounded
                placeholder="Itinerary"
                ></v-textarea>
            </v-col>
            <v-col cols="12" class="px-2" md="4">
                <p class="body-1 font-weight-medium">no. of days</p>
                <v-text-field type="number" :min="1" :rules="requiredRule" v-model="daysNo" rounded outlined></v-text-field>
            </v-col>
            <v-col cols="12" class="px-2" md="4">
                <p class="body-1 font-weight-medium">no. of nights</p>
                <v-text-field type="number" :min="1" :rules="requiredRule" v-model="nightsNo" rounded outlined></v-text-field>
            </v-col>
            <v-col cols="12" class="px-2" md="4">
                <p class="body-1 font-weight-medium">trip occupancy</p>
                <v-text-field type="number" :min="1" :rules="requiredRule" v-model="occupancy" rounded outlined></v-text-field>
            </v-col>
            <v-col cols="12" class="px-2" md="4">
                <p class="body-1 font-weight-medium">price/person</p>
                <v-text-field prefix="$" type="number" :min="1" :rules="requiredRule" v-model="personPrice" rounded outlined></v-text-field>
            </v-col>
            <v-col cols="12" class="px-2" md="4">
                <p class="body-1 font-weight-medium">solo price</p>
                <v-text-field prefix="$" type="number" :min="1" :rules="requiredRule" v-model="soloPrice" rounded outlined></v-text-field>
            </v-col>
            <v-col cols="12" class="px-2" md="4">
                <p class="body-1 font-weight-medium">limo price</p>
                <v-text-field prefix="$" type="number" :min="1" :rules="requiredRule" v-model="limoPrice" rounded outlined></v-text-field>
            </v-col>
            <v-col cols="12" class="px-2">
                <p class="body-1 font-weight-medium">payment link</p>
                <h4 v-for="(item, i) in links" :key="i" class="my-3">
                    {{item}}
                    <v-btn @click="links.splice(i, 1)" icon color="error" class="ml-5"><v-icon>mdi-trash-can</v-icon></v-btn>
                </h4>
                <div class="d-flex flex-wrap">
                    <v-text-field :rules="urlRule" v-model="link" rounded outlined></v-text-field>
                    <v-btn large :disabled="!link" @click="links.push(link)" text color="primary" class="mt-2"><v-icon left>mdi-plus-circle</v-icon>Add Payment link</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-btn :loading="loading" @click="saveTrip" rounded color="primary" large elevation="0" class="px-15 d-block mx-auto">Submit</v-btn>
      </v-form>
    </v-container>
</template>

<script>
import vendors from '@/web_services/vendors'
import main from '@/web_services/main'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      imgLoading: false,
      loading: false,
      alert: '',
      alertType: 'success',
      alertText: '',
      allCities: [],
      allTags: [],
      images: [],
      city: null,
      lat: '',
      lng: '',
      markers: [],
      center: {
        lat: 45.508,
        lng: -73.587
      },
      // tag: [],
      tags: [],
      valid: true,
      requiredRule: [
        v => !!v || 'This field is required'
      ],
      name: '',
      image: null,
      dateMenu: false,
      currentPlace: null,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      description: '',
      itinerary: '',
      daysNo: 1,
      nightsNo: 1,
      occupancy: 1,
      personPrice: 1,
      soloPrice: 1,
      limoPrice: 1,
      link: '',
      links: [],
      urlRule: [
        v => this.isURL(v) || 'URL is not valid'
      ]
    }
  },
  computed: {
    ...mapState(['agencyTrip']),
    previewImages () {
      const imagesURLs = []
      this.images.forEach(item => {
        imagesURLs.push(URL.createObjectURL(item))
      })
      return imagesURLs
    }
  },
  methods: {
    activateImgUpload () {
      this.imgLoading = true
      window.addEventListener('focus', () => {
        this.imgLoading = false
      }, { once: true })
      this.$refs.multipleUploader.click()
    },
    onFileChanged (e) {
      const images = Array.from(e.target.files)
      images.forEach(item => this.images.push(item))
    },
    singleImageUpload () {
      this.imgLoading = true
      window.addEventListener('focus', () => {
        this.imgLoading = false
      }, { once: true })
      this.$refs.singleUploader.click()
    },
    singleImageHandler (e) {
      const image = Array.from(e.target.files)[0]
      const formData = new FormData()
      formData.append('image', image, image.name)
      vendors.addImage(this.agencyTrip.id, formData).then(res => {
        this.agencyTrip.images = res.data.data.images
        this.showAlert('success', 'Added Successfully')
      }).catch(error => {
        let text = ''
        Object.entries(error.response.data.errors).forEach(([key, value]) => {
          value.forEach(item => {
            text += item + ' ! '
          })
        })
        this.showAlert('error', text)
      })
    },
    isURL (str) {
      if (!str) return true
      let url
      try {
        url = new URL(str)
      } catch (_) {
        return false
      }
      return url.protocol === 'http:' || url.protocol === 'https:'
    },
    setPlace (place) {
      this.city = place.formatted_address
      this.center.lat = place.geometry.location.lat()
      this.center.lng = place.geometry.location.lng()
      this.lat = this.center.lat
      this.lng = this.center.lng
      this.markers.push({ position: this.center })
    },
    deleteTripImage (tripId, imageId) {
      vendors.deleteImage(tripId, imageId).then(res => {
        this.showAlert('success', 'Deleted Successfully')
      }).catch(error => {
        let text = ''
        Object.entries(error.response.data.errors).forEach(([key, value]) => {
          value.forEach(item => {
            text += item + ' ! '
          })
        })
        this.showAlert('error', text)
      })
    },
    saveTrip () {
      this.$refs.detailsForm.validate()
      if (this.valid) {
        const formData = new FormData()
        formData.append('name', this.name)
        formData.append('date', this.date)
        formData.append('description', this.description)
        formData.append('itinerary', this.itinerary)
        formData.append('days', this.daysNo)
        formData.append('nights', this.nightsNo)
        formData.append('occupancy', this.occupancy)
        formData.append('price_per_person', this.personPrice)
        formData.append('solo_price', this.soloPrice)
        formData.append('limo_price', this.limoPrice)
        formData.append('city', this.city)
        formData.append('lat', this.center.lat)
        formData.append('lng', this.center.lng)
        if (this.$route.params.action !== 'add') formData.append('is_active', this.agencyTrip.is_active ? 1 : 0)
        this.links.forEach((item, i) => {
          formData.append(`payment_links[${i}]`, item)
        })
        this.tags.forEach((item, i) => {
          formData.append(`tags[${i}][id]`, item.id)
          formData.append(`tags[${i}][rank]`, item.rank)
        })
        if (this.image) formData.append('image', this.image, this.image.name)
        if (this.$route.params.action === 'add') {
          this.images.forEach((item, i) => {
            formData.append(`images[${i}]`, item, item.name)
          })
        }

        this.loading = true
        if (this.$route.params.action === 'add') {
          vendors.addTrip(formData).then(res => {
            this.showAlert('success', 'Created Successfully')
          }).catch(error => {
            let text = ''
            Object.entries(error.response.data.errors).forEach(([key, value]) => {
              value.forEach(item => {
                text += item + ' ! '
              })
            })
            this.showAlert('error', text)
          }).finally(() => {
            this.loading = false
          })
        } else {
          // const body = {
          //   name: this.name,
          //   description: this.description,
          //   itinerary: this.itinerary,
          //   date: this.date,
          //   days: this.days || 2,
          //   nights: this.nights,
          //   occupancy: this.occupancy,
          //   price_per_person: this.personPrice,
          //   solo_price: this.soloPrice,
          //   limo_price: this.limoPrice,
          //   city: this.city,
          //   lat: this.center.lat,
          //   lng: this.center.lng,
          //   payment_links: this.links,
          //   is_active: this.agencyTrip.is_active ? 1 : 0,
          //   tags: []
          // }
          // this.tags.map(tag => body.tags.push(tag.id))
          formData.append('_method', 'PUT')
          vendors.updateTrip(this.agencyTrip.id, formData).then(res => {
            this.showAlert('success', 'Created Successfully')
          }).catch(error => {
            let text = ''
            Object.entries(error.response.data.errors).forEach(([key, value]) => {
              value.forEach(item => {
                text += item + ' ! '
              })
            })
            this.showAlert('error', text)
          }).finally(() => {
            this.loading = false
          })
        }
      } else this.showAlert('warning', 'Fill Required fields')
    },
    showAlert (type, message) {
      this.alertText = message
      this.alertType = type
      this.alert = true
      window.scrollTo(0, 0)
      setTimeout(() => {
        this.alert = false
      }, 3000)
    },
    setInitialTripInfo () {
      this.city = this.agencyTrip.city
      this.tags = this.agencyTrip.tags
      this.name = this.agencyTrip.name
      this.date = this.agencyTrip.date
      this.description = this.agencyTrip.description
      this.itinerary = this.agencyTrip.itinerary
      this.daysNo = this.agencyTrip.days || 2
      this.nightsNo = this.agencyTrip.nights
      this.occupancy = this.agencyTrip.occupancy
      this.personPrice = this.agencyTrip.price_per_person
      this.soloPrice = this.agencyTrip.solo_price
      this.limoPrice = this.agencyTrip.limo_price
      this.links = this.agencyTrip.payment_link
      if (this.agencyTrip.lat && this.agencyTrip.lng) {
        this.lat = this.agencyTrip.lat
        this.lng = this.agencyTrip.lng
        this.center.lat = this.agencyTrip.lat
        this.center.lng = this.agencyTrip.lng
        this.markers.push({ position: this.center })
      }
    }
  },
  mounted () {
    if (this.$route.params.action === 'edit') document.getElementsByClassName('google-ac')[0].value = this.agencyTrip.city
  },
  created () {
    if (this.$route.params.action === 'edit' && !this.agencyTrip.id) this.$router.go(-1)
    if (this.$route.params.action === 'edit') this.setInitialTripInfo()
    main.getTripCities().then(res => {
      this.allCities = res.data.data
    }).catch(() => {})
    main.getTripTags().then(res => {
      const names = res.data.data.map(el => el.name)
      res.data.data.forEach(item => {
        if (!item.rank) item.rank = 1
      })
      this.allTags = res.data.data.filter(({ name }, index) => !names.includes(name, index + 1))
    }).catch(() => {})
  }
}
</script>

<style>
  .upload-div {
    border: 1px solid #FF8F95;
    cursor: pointer;
  }
  .google-ac {
    outline: none;
    border: solid 1px #cecece;
    width: 100%;
    padding: 8px;
    margin-bottom: 8px;
    height: 50px;
  }
</style>
