import Vue from 'vue'
import { baseUrl } from '../endpoints'

const timeout = 20000
export default {
  getTripCities (data) {
    return Vue.axios.get(baseUrl + 'cities', data, {
      timeout: timeout
    })
  },
  getTripTags (data) {
    return Vue.axios.get(baseUrl + 'tags', data, {
      timeout: timeout
    })
  },
  getCities () {
    return Vue.axios.get(baseUrl + 'cities', {
      timeout: timeout
    })
  }
}
