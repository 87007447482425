<template>
    <v-container>
      <v-expand-transition>
        <v-alert
        v-show="alert"
        border="left"
        text
        :type="alertType"
        >
          {{alertText}}
        </v-alert>
      </v-expand-transition>
      <h5 class="text-h5 mt-0 mb-5 primary--text text-capitalize">Bookings</h5>
      <booking-table :headers="headers" :items="bookings" listType="trips" @accept="acceptRequest" />
    </v-container>
</template>

<script>
import bookingTable from '@/components/vendors/bookingTable.vue'
import vendors from '../../web_services/vendors'

export default {
  components: {
    bookingTable
  },
  data () {
    return {
      headers: [
        { text: 'Booking', value: 'booking_reference' },
        { text: 'Trip Name', value: 'trip' },
        { text: 'Traveler Name', value: 'user.full_name' },
        { text: 'Check In', value: 'travel_date' },
        { text: 'Actions', value: 'action' }
      ],
      bookings: [],
      alert: false,
      alertType: 'success',
      alertText: ''
    }
  },
  methods: {
    acceptRequest (id) {
      vendors.confirmBooking(id).then(res => {
        this.getBookings()
        this.notify('success', 'The request is confirmed!')
      }).catch(() => {
        this.notify('error', 'The request is not confirmed!')
      })
    },
    notify (color, text) {
      this.alert = true
      this.alertType = color
      this.alertText = text
      window.scrollTo(0, 0)
      setTimeout(() => {
        this.alert = false
      }, 3000)
    },
    getBookings () {
      vendors.getBookings().then(res => {
        this.bookings = res.data.data
      }).catch(() => {})
    }
  },
  created () {
    this.getBookings()
  }
}
</script>
