<template>
    <v-container>
        <v-row justify="space-between" class="mx-5 mb-5">
            <h5 class="text-h5 primary--text">Profile</h5>
            <v-btn elevation="0" class="px-10" rounded large color="primary white--text" @click="infoDialog = true">Edit Profile</v-btn>
        </v-row>
        <v-expand-transition>
            <v-alert
            v-if="alert"
            border="left"
            text
            :type="alertType"
            >
                {{alertText}}
            </v-alert>
        </v-expand-transition>
        <v-card class="pa-5 my-5 rounded-xl" v-if="user">
            <v-row>
                <v-col cols="12" md="3" class="text-center">
                    <v-img class="rounded-circle mb-3 mx-auto" height="150" width="150" v-if="user.profile_photo" :src="user.profile_photo"></v-img>
                    <v-img v-else src="../../assets/images/agency.png" height="150" width="150" class="mx-auto mb-3 rounded-circle"></v-img>
                    <h3>{{user.agency.name}}</h3>
                    <!-- activateImgUpload -->
                    <v-btn small :loading="imgLoading" text color="primary" @click="imageUpdateDialog = true"><v-icon small>mdi-camera</v-icon> Change profile picture</v-btn>
                    <input
                    ref="uploader"
                    class="d-none"
                    type="file"
                    accept="image/*"
                    @change="onFileChanged"
                    >
                </v-col>
                <v-col cols="12" md="9" class="pl-5">
                    <div class="d-flex justify-space-between">
                        <h3 class="primary--text">About the company</h3>
                        <v-btn color="primary" @click="aboutDialog = true" icon><v-icon>mdi-clipboard-edit-outline</v-icon></v-btn>
                    </div>
                    <p class="body-1 pr-10 mt-5" v-if="user.about_me && user.about_me !== 'null'">{{user.about_me}}</p>
                    <p class="body-1" v-else>You haven't added description yet!</p>
                </v-col>
            </v-row>
        </v-card>

        <v-card class="pa-5 my-10 rounded-xl" :width="$vuetify.breakpoint.mdAndUp ? '70%' : '100%'" v-if="user">
            <div class="d-flex justify-space-between mb-5">
                <h3 class="primary--text">Company Info</h3>
                <v-btn color="primary" @click="infoDialog = true" icon><v-icon>mdi-clipboard-edit-outline</v-icon></v-btn>
            </div>
            <v-row class="text-capitalize">
                <v-col cols="12" sm="6" md="4">
                    <h3><v-icon class="mr-2">mdi-account</v-icon>agency name:</h3>
                </v-col>
                <v-col cols="12" sm="6" md="8">
                    <p class="text-h6 mx-5 grey--text">{{user.agency.name}}</p>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                    <h3><v-icon class="mr-2">mdi-account</v-icon>agency user name:</h3>
                </v-col>
                <v-col cols="12" sm="6" md="8">
                    <p class="text-h6 mx-5 grey--text">{{user.user_name}}</p>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                    <h3><v-icon class="mr-2">mdi-web</v-icon>website:</h3>
                </v-col>
                <v-col cols="12" sm="6" md="8">
                    <p class="text-h6 mx-5 grey--text">{{user.agency.website}}</p>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                    <h3><v-icon class="mr-2">mdi-phone</v-icon>contact number:</h3>
                </v-col>
                <v-col cols="12" sm="6" md="8">
                    <p class="text-h6 mx-5 grey--text">{{user.agency.phone}}</p>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                    <h3><v-icon class="mr-2">mdi-mail</v-icon>Email:</h3>
                </v-col>
                <v-col cols="12" sm="6" md="8">
                    <p class="text-h6 mx-5 grey--text">{{user.email}}</p>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                    <h3><v-icon class="mr-2">mdi-map</v-icon>location:</h3>
                </v-col>
                <v-col cols="12" sm="6" md="8">
                    <p class="text-h6 mx-5 grey--text">{{user.agency.address}}</p>
                </v-col>
            </v-row>
        </v-card>

        <!-- update dialogs -->
        <!-- image Dialog -->
        <v-dialog v-model="imageUpdateDialog" width="50%">
          <v-card class="pa-5 text-center">
            <croppa
            v-model="croppa"
            :width="150"
            :height="150"
            :quality="2"
            prevent-white-space
            :placeholder-font-size="18"
            @init="onInit"
            ></croppa>
            <div class="btns">
              <v-btn icon color="primary darken-2" @click="croppa.zoomIn()"><v-icon>mdi-magnify-plus</v-icon></v-btn>
              <v-btn icon color="primary darken-2" @click="croppa.zoomOut()"><v-icon>mdi-magnify-minus</v-icon></v-btn>
            </div>
            <v-divider></v-divider>
            <v-btn class="d-block mx-auto" :loading="imgLoading" text color="primary" @click="saveImage"><v-icon>mdi-download</v-icon> Save picture</v-btn>
          </v-card>
        </v-dialog>

        <!-- about dialog -->
        <v-dialog v-model="aboutDialog" width="50%">
            <v-card>
                <v-card-title class="primary--text mb-5">Update Description</v-card-title>
                <v-card-text>
                    <v-textarea
                    v-model="agency.about"
                    outlined
                    name="input-7-4"
                    label="About the company"
                    ></v-textarea>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :loading="updateLoading" tile depressed color="primary" @click="updateProfile">Update</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- info dialog -->
        <v-dialog v-model="infoDialog" width="70%">
            <v-card>
                <v-card-title class="primary--text mb-5">Update Company Profile</v-card-title>
                <v-card-text>
                    <v-form v-model="valid" ref="basicInfoForm">
                        <v-text-field id="agency-name" outlined rounded
                        height="60"
                        label="Agency Name"
                        v-model="agency.agency_name"
                        :rules="[v => (!!v && v.length > 3) || 'Item is required at least 3 characters', v => /^[_A-z]*((-|\s)*[_A-z])*$/.test(v) || 'Name Must be letters only with no spaces']"
                        >
                            <template v-slot:prepend-inner>
                                <div class="field-item">
                                    <v-icon color="forms">mdi-account-outline</v-icon>
                                </div>
                            </template>
                        </v-text-field>

                        <v-text-field id="agency-website" outlined rounded
                        height="60"
                        v-model="agency.website"
                        :rules="urlRule"
                        label="Agency Website"
                        >
                            <template v-slot:prepend-inner>
                                <div class="field-item">
                                    <v-icon color="forms">mdi-web</v-icon>
                                </div>
                            </template>
                        </v-text-field>

                        <v-text-field id="agency-address" outlined rounded
                        height="60"
                        v-model="agency.address"
                        :rules="[v => !!v || 'Address is required']"
                        label="Agency Address"
                        >
                            <template v-slot:prepend-inner>
                                <div class="field-item">
                                    <v-icon color="forms">mdi-card-account-details-outline</v-icon>
                                </div>
                            </template>
                        </v-text-field>

                        <v-text-field id="agency-phone" outlined rounded
                        height="60"
                        v-model="agency.phone"
                        label="Agency phone"
                        :rules="[v => !!v || 'phone is required', v => /^\d{11}$/.test(v) || 'Please enter a valid 11 digit phone number']"
                        >
                            <template v-slot:prepend-inner>
                                <div class="field-item">
                                    <v-icon color="forms">mdi-phone-outline</v-icon>
                                </div>
                            </template>
                        </v-text-field>

                        <v-text-field id="agency-email" outlined rounded type="email"
                        height="60"
                        v-model="agency.email"
                        label="Agency Email"
                        :rules="[v => !!v || 'Email is required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid']"
                        >
                            <template v-slot:prepend-inner>
                                <div class="field-item">
                                    <v-icon color="forms">mdi-email-outline</v-icon>
                                </div>
                            </template>
                        </v-text-field>

                        <v-text-field id="agency-displayName" outlined rounded
                        height="60"
                        v-model="agency.user_name"
                        label="Agency User Name"
                        :rules="[v => (!!v && v.length > 3) || 'Item is required at least 3 characters']"
                        >
                            <template v-slot:prepend-inner>
                                <div class="field-item">
                                    <v-icon color="forms">mdi-account-plus-outline</v-icon>
                                </div>
                            </template>
                        </v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :loading="updateLoading" tile depressed color="primary" @click="updateProfile">Update</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapState } from 'vuex'
import vendors from '@/web_services/vendors'
import user from '@/web_services/user'

export default {
  data () {
    return {
      alert: false,
      alertType: 'success',
      alertText: '',
      imgLoading: false,
      updateLoading: false,
      infoDialog: false,
      valid: true,
      urlRule: [
        v => !!v || 'Required.',
        v => this.isURL(v) || 'URL is not valid'
      ],
      aboutDialog: false,
      agency: {
        email: '',
        user_name: '',
        about: '',
        agency_logo: null,
        agency_name: '',
        address: '',
        phone: '',
        website: ''
      },
      croppa: {},
      imageUpdateDialog: false
    }
  },
  computed: {
    ...mapState(['user'])
  },
  methods: {
    activateImgUpload () {
      this.imgLoading = true
      window.addEventListener('focus', () => {
        this.imgLoading = false
      }, { once: true })
      this.$refs.uploader.click()
    },
    onFileChanged (e) {
      this.agency.agency_logo = e.target.files[0]
      this.updateProfile()
    },
    isURL (str) {
      let url
      try {
        url = new URL(str)
      } catch (_) {
        return false
      }
      return url.protocol === 'http:' || url.protocol === 'https:'
    },
    onInit () {
      this.croppa.addClipPlugin(function (ctx, x, y, w, h) {
        ctx.beginPath()
        ctx.arc(x + w / 2, y + h / 2, w / 2, 0, 2 * Math.PI, true)
        ctx.closePath()
      })
    },
    saveImage () {
      this.croppa.generateBlob((blob) => {
        blob.lastModifiedDate = new Date()
        blob.name = 'agency_profile_image_' + new Date().toDateString()
        this.agency.agency_logo = blob
        this.updateProfile()
      })
    },
    updateProfile () {
      const formData = new FormData()
      formData.append('email', this.agency.email)
      formData.append('user_name', this.agency.user_name)
      formData.append('about', this.agency.about || this.agency.about !== 'null' ? this.agency.about : '')
      formData.append('agency_name', this.agency.agency_name)
      formData.append('address', this.agency.address)
      formData.append('phone', this.agency.phone)
      formData.append('website', this.agency.website)
      if (this.agency.agency_logo) formData.append('agency_logo', this.agency.agency_logo, this.agency.agency_logo.name)
      this.updateLoading = true
      vendors.updateProfile(formData).then(res => {
        this.$store.dispatch('setUserData', res.data.data)
        this.showAlert('success', 'Updated Successfully')
      }).catch(error => {
        let text = ''
        Object.entries(error.response.data.errors).forEach(([key, value]) => {
          value.forEach(item => {
            text += item + ' ! '
          })
        })
        this.showAlert('error', text)
      }).finally(() => {
        this.updateLoading = false
        this.aboutDialog = false
        this.infoDialog = false
        this.imageUpdateDialog = false
      })
    },
    showAlert (type, message) {
      this.alertText = message
      this.alertType = type
      this.alert = true
      setTimeout(() => {
        this.alert = false
      }, 3000)
    },
    initiateAgencyObject () {
      this.agency = {
        email: this.user.email,
        user_name: this.user.user_name,
        about: this.user.about_me,
        agency_logo: null,
        agency_name: this.user.agency.name,
        address: this.user.agency.address,
        phone: this.user.agency.phone,
        website: this.user.agency.website
      }
    }
  },
  created () {
    if (this.user) {
      this.initiateAgencyObject()
    } else {
      user.profile().then(res => {
        this.$store.dispatch('setUserData', res.data.data)
        this.initiateAgencyObject()
      }).catch(err => {
        if (err.response.status === 401) {
          this.$store.dispatch('removeUser')
          this.$cookies.remove('gt_user_token')
          this.$router.push({ path: '/auth' })
        }
      })
    }
  }
}
</script>
