<template>
    <v-container>
        <v-expand-transition>
            <v-alert
            v-show="alert"
            border="left"
            text
            :type="alertType"
            >
            {{alertText}}
            </v-alert>
        </v-expand-transition>
        <div v-if="!booking && loading" class="text-center">
            <v-progress-circular
            :size="70"
            :width="7"
            color="primary"
            indeterminate
            ></v-progress-circular>
        </div>
        <div v-if="booking && !loading">
            <h1>{{booking.trip.name}}</h1>
            <h2 class="paragraph--text"><v-icon>mdi-map-marker</v-icon>{{booking.trip.city}}</h2>
            <h2 class=" primary--text">{{booking.paid_price | currency}}</h2>

            <v-card class="py-5 my-5">
                <v-row justify="space-between" align="center" class="text-capitalize">
                    <v-col cols="12" sm="6" md="4">
                        <h3 class="text-center mb-5">traveler</h3>
                        <div class="d-flex justify-center align-center mx-auto">
                            <v-img max-width="50" v-if="booking.user.profile_photo" :src="booking.user.profile_photo" class="rounded-circle mr-3"></v-img>
                            <v-avatar size="50" v-else color="primary">
                                <span>{{booking.user.full_name.charAt(0)}}</span>
                            </v-avatar>
                            <h4>{{booking.user.full_name}}</h4>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <h3 class="text-center mb-5">traveler contact</h3>
                        <h5 class="text-center paragraph--text">{{booking.user.email}}</h5>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <h3 class="text-center mb-5">travel date</h3>
                        <h5 class="text-center paragraph--text">{{new Date(booking.travel_date).toDateString()}}</h5>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <h3 class="text-center mb-5">no. of adults</h3>
                        <h5 class="text-center paragraph--text">{{booking.number_of_adults}}</h5>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <h3 class="text-center mb-5">no. of children</h3>
                        <h5 class="text-center paragraph--text">{{booking.number_of_children}}</h5>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <h3 class="text-center mb-5">no. of infants</h3>
                        <h5 class="text-center paragraph--text">{{booking.number_of_infants}}</h5>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <h3 class="text-center mb-5">Payment methods</h3>
                        <v-img max-width="50" src="../../assets/images/pay.png" class="mx-auto"></v-img>
                    </v-col>
                </v-row>
            </v-card>

            <div class="d-flex justify-end" v-if="booking.status === 'notConfirmed'">
                <v-btn rounded class="px-15 mx-1" color="success" @click="acceptRequest(booking.id)" elevation="0">Accept</v-btn>
            </div>
            <div v-else class="d-flex justify-space-between text-capitalize">
                <h5>update trip status</h5>
                <h5 class="success--text">Request Accepted</h5>
                <!-- <v-menu
                bottom
                left
                offset-y
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                        :color="booking.status === 'accepted' ? 'success' : 'error'"
                        text
                        v-bind="attrs"
                        v-on="on"
                        >
                            {{booking.status === 'accepted' ? 'Request Accepted' : 'Request Rejected'}}
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item v-if="booking.status === 'accepted'" @click="booking.status = 'rejected'">
                            <v-list-item-title>Reject Request</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="booking.status === 'rejected'" @click="booking.status = 'accepted'">
                            <v-list-item-title>Accept Request</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="booking.status = 'waiting'">
                            <v-list-item-title>Wait List</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu> -->
            </div>
        </div>
        <p v-if="!loading && !booking" class="primary--text text-center">Couldn't fetch the trip booking !</p>
    </v-container>
</template>

<script>
import vendors from '../../web_services/vendors'

export default {
  data () {
    return {
      booking: null,
      loading: true,
      alert: false,
      alertType: 'success',
      alertText: ''
    }
  },
  methods: {
    acceptRequest (id) {
      vendors.confirmBooking(id).then(res => {
        this.getBookings()
        this.notify('success', 'The request is confirmed!')
      }).catch(() => {
        this.notify('error', 'The request is not confirmed!')
      })
    },
    notify (color, text) {
      this.alert = true
      this.alertType = color
      this.alertText = text
      window.scrollTo(0, 0)
      setTimeout(() => {
        this.alert = false
      }, 3000)
    }
  },
  created () {
    vendors.getBookingDetails(this.$route.params.id).then(res => {
      this.booking = res.data.data
    }).catch(() => {}).finally(() => { this.loading = false })
  }
}
</script>
